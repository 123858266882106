import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import { ReactNode, Suspense, lazy } from "react";
import CustomDialog from "./components/ReUsableComponents/CustomDialog";
import { useSelector } from "react-redux";
import { RootState } from "./redux/Store/store";
import ErrorPage from "./pages/ErrorPage";
import FullTest from "./pages/FullTest";
import ModuleOneInstructions from "./components/DialogComponents/ModuleOneInstructions";
import SaveAndExit from "./components/DialogComponents/SaveAndExit";
import ModuleTwoInstructions from "./components/DialogComponents/ModuleTwoInstructions";
import LatexUtility from "./pages/LatexUtility";
import SignUp from "./pages/LogInPage/SignUp";
import TokenDialogue from "./pages/TokenDialogue";
import LogIn from "./pages/LogInPage/LogIn";
import ResultsDialog from "./components/ReUsableComponents/ResultsDialog";
import ReferenceMathsEquations from "./pages/ReferenceMathsEquations";
import RedirectionPage from "./pages/RedirectionPage";
import Dashboard from "./pages/NewDashboard/DashboardV1";
import QuestionPageInBank from "./pages/QuestionPageInBank";
import ExplanationInBank from "./components/DialogComponents/ExplanationInBank";
import ShortcutsDialog from "./components/DialogComponents/Shortcutdialog";
import HelpDialog from "./components/DialogComponents/HelpDialog";
import AssistiveTechnology from "./components/DialogComponents/AssistiveTechnologyDialog";
import LoginV1 from "./pages/LogInPage/LoginV1";
import ProfileSetup from "./pages/LogInPage/ProfileSetup";
import ParentDetails from "./pages/LogInPage/ParentDetails";
import StudentDetails from "./pages/LogInPage/StudentDetails";
import EmailVerificationPrompt from "./pages/LogInPage/EmailVerificationPrompt";
import useRoutesConfig,{ ParamsWrapper } from "./pages/LogInPage/EmailVerficationPromptConfig";
import ProfileAssociation from "./pages/LogInPage/ProfileAssociation";
import SignUpForm from "./pages/LogInPage/SignupV1";
import TestInstructions from "./components/Exam/TestInstructions";
import DiscardInfo from "./components/DialogComponents/DIscardInfo";
import ResultsPage from "./pages/NewResults/ResultsPage";
import ParentDashboard from "./pages/NewDashboard/ParentDashboard";
import TestResults from "./components/TestResults";
import ChangePassword from "./components/DialogComponents/ChangePassword";
import EditProfile from "./components/DialogComponents/EditProfile";
import AddParentDetails from "./components/DialogComponents/AddParentDetails";
import AddStudentDetails from "./components/DialogComponents/AddStudentDetails";
import ApiErrorPage from "./pages/ApiErrorPage";
import SocialSignup from "./pages/LogInPage/SocialSignup";
import OAuthVerification from "./pages/LogInPage/OAuthVerification";
import CustomSat from "./components/CustomSat";

const QuestionBank = lazy(() => import("./pages/QuestionBank"));
const Pricing = lazy(() => import("./pages/Pricing"));
const AboutUs = lazy(() => import("./pages/About"));
const TransactionPage = lazy(() => import("./pages/TransactionSummary"));

type DialogComponentType = {
  [key: string]: ReactNode;
};

const App = () => {
  const dialogType = useSelector(
    (state: RootState) => state.root.dialog.dailogType
  );
  const routesConfig = useRoutesConfig();
  const DIALOG_TITLES: any = {
    signIn: "",
    // Add other dialog titles here
  };

  const DIALOG_WIDTHS: any = {
    writingInstructions: "max-w-6xl",
    mathsInstructions: "max-w-6xl",
    resultsExplaination: "max-w-7xl",
    shortcutsDialog: "max-w-4xl",
    helpDialog: "max-w-4xl",
    assistiveTechnologyDialog: "max-w-4xl",
    saveExitDialogue: "xl:max-w-4xl max-w-xl",
    discardInfo: "max-w-3xl",
    startTest: "w-full h-screen",
    customSat: "max-w-[785px] !p-0",
    default: "max-w-lg",
  };

  const getDialogTitle = (type: any) => DIALOG_TITLES[type] || "";

  const getDialogWidth = (type: any) =>
    DIALOG_WIDTHS[type] || DIALOG_WIDTHS.default;

  const dialogComponent: DialogComponentType = {
    signIn: <SignUp isAnimated={false} setIsAnimated={() => {}} />,
    tokenDialogue: <TokenDialogue />,
    startTest: <TestInstructions />,
    writingInstructions: <ModuleOneInstructions />,
    saveExitDialogue: <SaveAndExit />,
    shortcutsDialog: <ShortcutsDialog />,
    helpDialog: <HelpDialog />,
    assistiveTechnologyDialog: <AssistiveTechnology />,
    discardInfo: <DiscardInfo />,
    changePassword: <ChangePassword />,
    editProfile: <EditProfile />,
    addParentDetails: <AddParentDetails />,
    addStudentDetails: <AddStudentDetails />,
    customSat: <CustomSat />,

    mathsInstructions: (
      <div>
        <ModuleTwoInstructions />
      </div>
    ),
    resultsExplaination: (
      <div>
        <ResultsDialog />
      </div>
    ),
    explanationQuestionInBank: (
      <div>
        <ExplanationInBank />
      </div>
    ),
  };

  return (
    <div>
      <Router>
        <Suspense fallback={<h1>Loading...............</h1>}>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              {/* <Route index element={<Home />} /> */}
              <Route path="home" element={<Home />} />
              {/* <Route path="/practices/results/:id" element={<ExamCompletedCard />} /> */}
              <Route path="practices/results" element={<ResultsPage />} />
              <Route path="categories" element={<QuestionBank />} />
              <Route path="products" element={<Pricing />} />
              <Route path="about" element={<AboutUs />} />
              <Route path="latexPageValidation" element={<LatexUtility />} />
              <Route path="transactionsummary" element={<TransactionPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="categories/:id" element={<QuestionPageInBank />} />
              <Route path="/parentdashboard" element={<ParentDashboard />} />
              <Route path="results" element={<TestResults />} />
              <Route path="/practices/:id" element={<FullTest />} />
              <Route path="/error" element={<ApiErrorPage />} />
            </Route>
            <Route path="/practices/results/:id" element={<TestResults />} />
            <Route index element={<LoginV1 />} />
            <Route index path="/login" element={<LoginV1 />} />
            <Route index path="/loginv1" element={<LogIn />} />
            <Route index path="/register" element={<SignUpForm />} />
            <Route path="/profilesetup" element={<ProfileSetup />} />
            <Route path="/socialsignup" element={<SocialSignup />} />
            <Route path="/oauth" element={<OAuthVerification/>}/>
            <Route
              path="/parentprofile"
              element={<ParentDetails isParentRoute={true} />}
            />
            <Route path="/parentassociate" element={<ProfileAssociation />} />
            <Route
              index
              path="/studentprofile"
              element={<StudentDetails isStudentRoute={true} />}
            />
            <Route path="/studentassociate" element={<ProfileAssociation />} />
            {routesConfig.map(
              ({
                path,
                title,
                description,
                btnLabel,
                btnDisable,
                icon,
                onClickHandler,
                additionalContent,
                img,
              }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    path === "/verifyemail/:id" ||
                    path === "/passwordsetter/:userId" ? (
                      <ParamsWrapper
                        title={title}
                        description={description}
                        btnLabel={btnLabel}
                        icon={icon}
                        btnDisable={btnDisable}
                        onClickHandler={onClickHandler}
                        additionalContent={additionalContent}
                        img={img}
                      />
                    ) : (
                      <EmailVerificationPrompt
                        title={title}
                        description={description}
                        btnLabel={btnLabel}
                        icon={icon}
                        btnDisable={btnDisable}
                        onClickHandler={onClickHandler}
                        img={img}
                      >
                        {additionalContent}
                      </EmailVerificationPrompt>
                    )
                  }
                />
              )
            )}
            <Route
              index
              path="/cambri/:isLogin/:token"
              element={<RedirectionPage />}
            />

            <Route path="/references" element={<ReferenceMathsEquations />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
        <CustomDialog
          dialogTitle={getDialogTitle(dialogType)}
          maxWidth={getDialogWidth(dialogType)}
        >
          {dialogType && dialogComponent[dialogType]}
        </CustomDialog>
      </Router>
    </div>
  );
};

export default App;
