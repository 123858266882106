import React, { useEffect, useState } from "react";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import { LuUser } from "react-icons/lu";
import { RiEyeCloseLine } from "react-icons/ri";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { setEmailError } from "../../redux/Slices/loginSlice";
import { useDispatch } from "react-redux";
import { GrCircleAlert } from "react-icons/gr";
import { useSignupMutation } from "../../redux/API/Login/loginApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setItem } from "../../utils/token";
import { usePasswordValidation } from "../../utils";
import AuthButton from "./LoginButton";
import workingMan from "../../assets/Images/working-man.svg";

const SignUpForm = () => {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const [signUpData, setSignUpData] = useState({
    userName: "",
    confirmUserName: "",
    password: "",
    confirmPassword: "",
  });
  const { userName, confirmUserName, password, confirmPassword } = signUpData;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { validatePassword } = usePasswordValidation();

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [signup, { isLoading }] = useSignupMutation();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name, value, "name, value");
    setError("");
    setSignUpData((prevState) => ({
      ...prevState,
      [name]: value?.trim(),
    }));
  };

  const validateEmail = (email: string) => {
    if (!emailPattern.test(email) && email) {
      dispatch(setEmailError("Enter a valid email"));
      return false;
    } else {
      dispatch(setEmailError(""));
    }
    return true;
  };

  const handleError = (error: any) => {
    toast.error(error?.data);
  };

  const handleSignUp = async () => {
    const isEmailValid = validateEmail(userName);
    const isEmailsMatch = userName === confirmUserName;
    const isPasswordsMatch = password === confirmPassword;
    const isPasswordvalid = validatePassword(password);

    if (
      !(isEmailValid && isEmailsMatch && isPasswordsMatch && isPasswordvalid)
    ) {
      if (!isEmailValid) {
        setError("Enter a valid email");
      } else if (!isEmailsMatch) {
        setError("Emails do not match");
      } else if (!isPasswordsMatch) {
        setError("Passwords do not match");
      } else if (!isPasswordvalid) {
        setError(
          "Password must be at least 8 characters long, contain at least one special character, and one uppercase letter."
        );
      } else {
        setError("");
      }
      return;
    }

    try {
      const payload = {
        userName: userName.trim().toLowerCase(),
        password: password.trim(),
      };
      const res: any = await signup(payload).unwrap();
      if (res.status === "success") {
        setItem("userEmail", userName);
        setItem("userId", res?.data?.id);
        navigate("/verifyemail");
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const disableSignup = () => {
    if (
      !userName ||
      !confirmUserName ||
      !password ||
      !confirmPassword ||
      isLoading
    ) {
      return true;
    } else return false;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (userName && confirmUserName && password && confirmPassword) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          handleSignUp();
        }
      };
      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [signUpData]);

  return (
    <div className="bg-white flex justify-center items-center h-screen w-full mx-auto font-gully">
      <div className="w-1/2 h-screen hidden lg:block">
        <img
          src={workingMan}
          alt="Placeholder"
          className="object-cover w-full h-screen"
        />
      </div>
      <div className="w-1/2 flex flex-col items-center lg:p-[120px] 3xl:p-[250px]">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="flex justify-center items-center gap-x-2 mb-9">
            <p className="font-bold text-[#283044] text-center text-4xl font-pretendard cursor-pointer">
              FullMarks! <span className="text-appPrimaryColor">SATprep</span>
            </p>
          </div>{" "}
          <div className="flex justify-center items-center flex-col">
            {/* {emailError && (
            <div className="flex items-center gap-x-1  pb-3">
              <IoWarningOutline size={14} className="text-[#D00000]" />
              <p className="text-[#D00000] text-xs font-semibold">
                {emailError}
              </p>
            </div>
          )} */}
            {error && (
              <div className="flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
                <div>
                  <GrCircleAlert
                    size={24}
                    className="text-[#D00000] mr-[10px]"
                  />
                </div>
                <p className="text-[#D00000] text-base font-semibold">
                  {error}
                </p>
              </div>
            )}
            <div className="flex justify-center items-center flex-col w-full md:!w-[446px]">
              <CustomTextField
                placeholder="Email"
                type="text"
                name="userName"
                value={userName}
                onChangeHandler={handleChange}
                className="md:!w-[446px] w-full mb-4 !border-[#646464]"
              />
              <CustomTextField
                placeholder="Confirm Email"
                type="text"
                name="confirmUserName"
                value={confirmUserName}
                onChangeHandler={handleChange}
                className="md:!w-[446px] w-full mb-4 !border-[#646464]"
                onPaste={handlePaste}
              />
              <div className="w-full mb-4 relative md:!w-[446px]">
                <CustomTextField
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password?.trim()}
                  onChangeHandler={handleChange}
                  className="md:!w-[446px] w-full !border-[#646464]"
                />
                {signUpData.password && (
                  <button
                    className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <MdOutlineRemoveRedEye />
                    ) : (
                      <RiEyeCloseLine />
                    )}
                  </button>
                )}
              </div>
              <div className="w-full mb-6 relative md:!w-[446px]">
                <CustomTextField
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={confirmPassword?.trim()}
                  onChangeHandler={handleChange}
                  className="md:!w-[446px] w-full !border-[#646464]"
                  onPaste={handlePaste}
                />
                {signUpData.confirmPassword && (
                  <button
                    className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <MdOutlineRemoveRedEye />
                    ) : (
                      <RiEyeCloseLine />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <AuthButton
              text="Sign Up"
              icon={<LuUser />}
              iconPosition="left"
              onClick={handleSignUp}
              loader={isLoading}
              disabled={disableSignup()}
            />
          </div>
          <div className="flex justify-center items-center mt-9 gap-x-1">
            <p className="text-[#202020]">Already have an account?</p>
            <button
              className="underline text-[#327AEC] flex justify-center text-base cursor-pointer"
              onClick={() => {
                navigate("/login");
                setSignUpData({
                  userName: "",
                  confirmUserName: "",
                  password: "",
                  confirmPassword: "",
                });
              }}
            >
              Sign in!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
