import { useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { MdPlayArrow } from "react-icons/md";
import {
  changeCurrentModuleName,
  setCount,
  setCurrentTime,
  setExtendedBreaks,
  setExtraBreaks,
  setHasHighlightPopupBeenShown,
  setIsModuleCompleted,
  setIsPopUp,
  setIsResumeTest,
  setIsTestInstructionsOpen,
  setMathModuleOneQuestions,
  setMathModuleTime,
  setMathModuleTwoQuestions,
  setReadingModuleOneQuestions,
  setReadingModuleTime,
  setReadingModuleTwoQuestions,
  setReviewPageClicked,
  setSelectTestType,
  setStrikedOptionsData,
  setTestDifficulty,
  setTestId,
  setTestName,
  setUserTestId,
} from "../../redux/Slices/startFullTestSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog, openDialog } from "../../redux/Slices/dialogSlice";
import { RootState } from "../../redux/Store/store";
import { setItem } from "../../utils/token";
import { clearAnswers } from "../../redux/Slices/resultSlice";
import { useLazyGetStartTestDataV1Query } from "../../redux/API/StartTest/startTestApi";
import Dropdown from "../../pages/LogInPage/Dropdown";

interface Section {
  name: string;
  baseTime: number;
  currentTime: number;
  difficulty: "EASY" | "MEDIUM" | "HARD" | "BASELINE";
  percentage: number;
}

const CustomSat = () => {
  const [sections, setSections] = useState<Section[]>([
    {
      name: "Reading",
      baseTime: 32,
      currentTime: 32,
      difficulty: "MEDIUM",
      percentage: 0,
    },
    {
      name: "Math",
      baseTime: 35,
      currentTime: 35,
      difficulty: "MEDIUM",
      percentage: 0,
    },
  ]);

  const dispatch = useDispatch();
  const { extendedBreaks,extraBreaks,selectTestType,mathModuleTime,readModuleTime,testDifficulty } = useSelector(
    (state: RootState) => state.root.startFullTest
  );

 const handleSliderChange = (sectionIndex: number, newPercentage: number) => {
   // Round to nearest 10%
   const roundedPercentage = Math.round(newPercentage / 10) * 10;

   // Ensure percentage is within bounds (-50 to 50 is a common range, adjust as needed)
   const clampedPercentage = Math.max(-50, Math.min(100, roundedPercentage));

   setSections((prev) =>
     prev.map((section, idx) => {
       if (idx === sectionIndex) {
         const adjustedTime = Math.round(
           section.baseTime * (1 + clampedPercentage / 100)
         );
         const convertedSeconds = adjustedTime * 60; // Convert minutes to seconds

         if (sectionIndex === 0) {
           dispatch(setCurrentTime(convertedSeconds));
           dispatch(setReadingModuleTime(convertedSeconds));
         } else {
           dispatch(setMathModuleTime(convertedSeconds));
         }

         return {
           ...section,
           currentTime: adjustedTime,
           percentage: clampedPercentage,
         };
       }
       return section;
     })
   );
 };

  const handleDifficultyChange = (
    sectionIndex: number,
    difficulty: "EASY" | "MEDIUM" | "HARD"
  ) => {
    if(sectionIndex === 0){
    dispatch(setTestDifficulty({ key: "Reading", value: difficulty }));
    }
    if (sectionIndex === 1) {
      dispatch(setTestDifficulty({ key: "Math", value: difficulty }));
    }
  };

  const handleTestTypeSelection = (val: any) => {
    dispatch(setSelectTestType(val.value));
    setSelectedOption(val.label)
  };

  const getPositionPercentage = (value: number) => {
    return ((value + 20) / 120) * 100;
  };

  const testDifficultyColor = [
    {
      name: "Easy",
      color: "#0B6E4F",
      value:'EASY'
    },
    {
      name: "Moderate",
      color: "#327AEC",
      value:'MEDIUM'
    },
    {
      name: "Hard",
      color: "#D00000",
      value:'HARD'
    },
  ];

  const handleExtraCheckbox = (e:any) => {
    dispatch(setExtraBreaks(e.target.checked));
  }

    const getCustomSATInfo = () => {
      if (selectTestType !== "custom") {
        return {};
      } else
        return {
          readingAndWriting: {
            module1DifficultyLevel: testDifficulty?.Reading,
            module2DifficultyLevel: testDifficulty?.Reading,
            time: readModuleTime,
          },
          mathematics: {
            module1DifficultyLevel: testDifficulty?.Math,
            module2DifficultyLevel: testDifficulty?.Math,
            time: mathModuleTime,
          },
          extraBreaks: extraBreaks ? "500" : "",
          extendedBreaks: extendedBreaks ? "1200" : "",
        };
    };
  const [getStartTestDataV1] = useLazyGetStartTestDataV1Query();
  const [startDisable, setStartDisable] = useState(false);

    const calculateExactAnswer = (options: any, answerValue: any) => {
      if (options.length > 0) {
        let correctOption = options.find((item: any) => item.isCorrectAnswer);
        return correctOption.answerOption;
      } else {
        return answerValue;
      }
    };

    const addQuestionId = (questions: any) => {
      return questions?.map((item: any, index: any) => ({
        ...item,
        qIdUi: index + 1,
        exactAnswer: calculateExactAnswer(item.answers, item.answerValue),
      }));
    };

    const startTest = async (isPreview: boolean) => {
      try {
        let payload = getCustomSATInfo();
        let isCustomTest = selectTestType === "custom";
        let response = await getStartTestDataV1({
          isPreviewTest: isPreview,
          isCustomTest,
          payload,
        });
        if (response.isSuccess) {
          if (response.data.data) {
            localStorage.setItem(
              "currentTestName",
              response.data.data.testData.name
            );
            dispatch(setTestId(response.data.data.testData.testId));
            dispatch(setTestName(response.data.data.testData.name));

            let questions = response.data.data.testData.questions;
            let readingQuestions = questions[`ReadingAndWriting`];
            let mathsQuestions = questions[`Mathematics`];
            let readingModuleOneQuestions = [];
            let readingModuleTwoQuestions = [];
            let mathsModuleOneQuestions = [];
            let mathsModuleTwoQuestions = [];

            if (response.data.data.testData.testType === "PREVIEW") {
              setItem("testType", "PREVIEW");
              readingModuleOneQuestions = addQuestionId(
                readingQuestions[`Module1`]
              );
              mathsModuleOneQuestions = addQuestionId(
                mathsQuestions[`Module1`]
              );
              dispatch(setReadingModuleOneQuestions (readingModuleOneQuestions));
              dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
            } else {
              readingModuleOneQuestions = addQuestionId(
                readingQuestions[`Module1`]
              );
              readingModuleTwoQuestions = addQuestionId(
                readingQuestions[`Module2`]
              );
              mathsModuleOneQuestions = addQuestionId(
                mathsQuestions[`Module1`]
              );
              mathsModuleTwoQuestions = addQuestionId(
                mathsQuestions[`Module2`]
              );
              dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
              dispatch(setReadingModuleTwoQuestions(readingModuleTwoQuestions));
              dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
              dispatch(setMathModuleTwoQuestions(mathsModuleTwoQuestions));
              dispatch(setIsTestInstructionsOpen(true));
              dispatch(closeDialog());
            }
             dispatch(setUserTestId(response.data.data.testData.userTestId));
          }

          dispatch(changeCurrentModuleName("writingModule1"));
          setStartDisable(false);
        } else {
          setStartDisable(false);
          console.error("Error:", response.error);
        }
      } catch (e) {
        setStartDisable(false);
        console.error("Error:", e);
      }
    };


   const handleStartTest = (isPreview: boolean) => {
     dispatch(setCount(""));
     setStartDisable(true);
     dispatch(setHasHighlightPopupBeenShown(false));
     if (isPreview) {
       dispatch(setStrikedOptionsData([]));
       startTest(true);
       setItem("testType", "PREVIEW");
       dispatch(setCurrentTime(0));
     } else {
       dispatch(openDialog("customSat"));
       setItem("testType", "FULL");
       startTest(false);
       dispatch(setCurrentTime(readModuleTime));
     }
     dispatch(clearAnswers());
     dispatch(setReviewPageClicked(false));
     dispatch(setIsModuleCompleted(false));
     dispatch(setIsPopUp(false));
     dispatch(setIsResumeTest(false));
   };

   const testType = [
     {
       label: "Standard SAT",
       value:'standard'
     },
     {
       label: "Custom SAT",
       value:'custom'
     },
   ];
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption,setSelectedOption] = useState("");

  return (
    <div className="max-w-[780px] mx-auto px-12 py-8 bg-white rounded-lg shadow-sm mt-10 font-gully">
      <h1 className="text-[40px] font-bold mb-8">
        Choose A Full-Length Practice Test
      </h1>

      <div className="mb-6 custom-select-container">
        <div className="w-full mt-6">
          <label className="block text-sm font-semibold">TEST TYPE</label>
          <Dropdown
            getOption={(option: any) => `${option.label}`}
            onChangeHandler={handleTestTypeSelection}
            setOpen={setDropdownOpen}
            open={dropdownOpen}
            selected={selectedOption}
            options={testType || []}
            label="TEST TYPE"
            name="Select"
          />
        </div>
      </div>
      {selectTestType === "custom" && (
        <div>
          {/* <div className="mb-8">
            <label className="block text-sm font-semibold mb-4">
              TEST DIFFICULTY
            </label>
            <div className="grid grid-cols-2">
              {sections.map((section, idx) => (
                <div
                  key={section.name}
                  className={`${
                    idx === 0 ? "border-r-[3px] border-[#E1E1E1] pr-8" : "pl-8"
                  }`}
                >
                  <p className="mb-4 text-center text-base text-[#646464]">
                    {section.name}
                  </p>
                  <div className="flex gap-1">
                    {testDifficultyColor.map((item) => (
                      <label key={item.name} className="role mr-2">
                        <span className={`text-[${item.color}] ml-3 text-base`}>
                          {item.name}
                        </span>
                        <input
                          value="level"
                          type="radio"
                          name={`radio-${idx}`}
                          checked={testDifficulty[section.name] === item.value}
                          onChange={() =>
                            handleDifficultyChange(
                              idx,
                              item.value as "EASY" | "MEDIUM" | "HARD"
                            )
                          }
                        />
                        <span className="checkmark"></span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          <div className="mb-8">
            <label className="block text-sm font-semibold mb-2 text-[#283044]">
              EXTENDED/REDUCE TIME
            </label>
            <p className="text-gray-600 mb-4 text-base">
              Add extra time to your test to help yourself complete all the
              questions or reduce the time to challenge yourself
            </p>

            <div className="grid grid-cols-2">
              {sections.map((section, idx) => (
                <>
                  <div
                    className={`w-full ${
                      idx === 0
                        ? "border-r-[3px] border-[#E1E1E1] pr-8"
                        : "pl-8"
                    }`}
                    key={section.name}
                  >
                    <div className="mb-2">
                      <div className="flex justify-between items-center flex-col">
                        <p className="text-[#646464] text-base text-center mb-[6px]">
                          {section.name}
                        </p>
                        <div className="flex items-center gap-1">
                          <p className="text-[21px] font-bold text-center mb-4">
                            {section.currentTime} mins
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="relative pt-8 pb-4">
                      <div
                        className="absolute -top-4 left-0 transform -translate-x-1/2 transition-all duration-150"
                        style={{
                          left: `${getPositionPercentage(section.percentage)}%`,
                        }}
                      >
                        <div className="relative">
                          <div className="bg-blue-500 text-white text-sm px-3 py-1 rounded-full min-w-[52px] text-center">
                            {section.percentage}
                          </div>
                          <span className="absolute inner-block border-[10px] left-[31%] top-[25px] border-x-transparent border-b-0 border-blue-500"></span>
                        </div>
                      </div>
                      <div className="relative">
                        <div className="absolute w-full h-[6px] bg-gray-200 rounded-full"></div>
                        <div
                          className="absolute h-[6px] bg-[#283044] rounded-full transition-all duration-150"
                          style={{
                            width: `${getPositionPercentage(
                              section.percentage
                            )}%`,
                            left: 0,
                          }}
                        ></div>
                        <input
                          type="range"
                          min="-20"
                          max="100"
                          value={section.percentage}
                          onChange={(e) =>
                            handleSliderChange(idx, parseInt(e.target.value))
                          }
                          className="absolute w-full h-[6px] opacity-0 cursor-pointer z-10"
                        />
                        <div
                          className="absolute top-1/2 -translate-y-1/2 transition-all duration-150"
                          style={{
                            left: `${getPositionPercentage(
                              section.percentage
                            )}%`,
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[22px] h-[22px] rounded-full bg-[#7E828E]"></div>
                          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4 h-4 bg-white border-2 border-[#283044] rounded-full shadow-sm"></div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-2">
                      <span className="text-sm text-gray-500">
                        Slide to adjust the time
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="mb-8">
            <label className="block text-sm font-semibold mb-4">BREAKS</label>
            <p className="text-black mb-4 text-base">
              Change how you take your breaks during the test
            </p>

            <div className="space-y-3 text-base">
              <label className="flex items-center gap-3">
                <div className="checkbox-container">
                  <label className="break-checkbox">
                    <input
                      type="checkbox"
                      checked={extraBreaks}
                      onChange={handleExtraCheckbox}
                    />
                    <span className="checkbox-icon"></span>
                  </label>
                </div>
                <span className="ml-[12px] mt-[10px]">
                  Extra Breaks{" "}
                  <span className="text-gray-500">
                    (5 mins would be added after 1st module of each section)
                  </span>
                </span>
              </label>

              <label className="flex items-center gap-3">
                <div className="checkbox-container">
                  <label className="break-checkbox">
                    <input
                      type="checkbox"
                      checked={extendedBreaks}
                      onChange={(e) =>
                        dispatch(setExtendedBreaks(e.target.checked))
                      }
                    />
                    <span className="checkbox-icon"></span>
                  </label>
                </div>
                <span className="ml-[12px] mt-[10px]">
                  Extended Breaks{" "}
                  <span className="text-gray-500">
                    (10 mins would be added to regular module break)
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <AuthButton
          text="Back"
          icon={<BiChevronLeft size={16} />}
          iconPosition="left"
          onClick={() => {
            dispatch(setExtendedBreaks(false));
            dispatch(setExtraBreaks(false));
            dispatch(closeDialog());
          }}
          loader={false}
          disabled={false}
        />
        <AuthButton
          text="Start"
          icon={<MdPlayArrow size={16} />}
          iconPosition="right"
          onClick={() => {
            handleStartTest(false);
          }}
          loader={false}
          disabled={selectTestType === ""}
        />
      </div>
    </div>
  );
};

export default CustomSat;
