import { Fragment, useEffect, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { FaCheck } from "react-icons/fa";
type DropDownType = {
  onChangeHandler: (value: string | number, name: string) => void;
  selected: string | number;
  setOpen: (value: boolean) => void;
  open: boolean;
  options?: any;
  isError?: boolean;
  label?: string;
  getOption?: any;
  name?: string;
};

export default function Dropdown({
  onChangeHandler,
  selected,
  setOpen,
  open,
  options,
  isError,
  label,
  getOption,
  name = "",
}: Readonly<DropDownType>) {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);
  return (
    <div className="mt-2" ref={dropdownRef}>
      <Listbox value={selected} onChange={(val) => onChangeHandler(val, name)}>
        <div className="relative mt-1">
          <Listbox.Button
            style={{ boxShadow: "0px 13.44px 22.08px 0px #1C252C14" }}
            onClick={() => setOpen(!open)}
            className={`${selected ? "bg-white" : "!bg-[#F5F9FF]"} ${
              isError ? "border-[#D00000] text-[#D00000]" : "!border-[#646464]"
            } h-[47px] rounded-xl font-medium border  relative w-full cursor-default bg-white py-2 px-[21px] text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}
          >
            <span
              className={`${selected ? "text-black" : "text-[#646464]"} ${
                isError ? "text-[#D00000]" : ""
              } block truncate text-base`}
            >
              {selected ||  label}
            </span>
            <span
              className={`${
                isError ? "text-[#D00000]" : "text-[#646464]"
              } pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[21px]`}
            >
              {open ? <SlArrowUp size={20} /> : <SlArrowDown size={20} />}
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              style={{ boxShadow: "0px 13.44px 22.08px 0px #1C252C14" }}
              className="font-medium rounded-xl z-10 absolute mt-1 max-h-60 w-full overflow-auto bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {options?.length > 0 ? (
                options.map((item: any) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-[18px] px-[21px] text-base font-medium ${
                        active ? "bg-[#327AEC] text-white" : "text-gray-900"
                      }`
                    }
                    value={item}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {getOption(item)}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <FaCheck className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <Listbox.Option
                  className={({ active }) =>
                    `relative cursor-default select-none py-[18px] px-[21px] text-base font-medium ${
                      active ? "bg-[#327AEC] text-white" : "text-gray-900"
                    }`
                  }
                  value={null}
                >
                  No Options Available
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
