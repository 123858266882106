import api from '../createApi'

export const loginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (payload) => ({
        url: "/auth/signup",
        method: "POST",
        body: payload,
      }),
    }),
    signIn: builder.mutation({
      query: (payload) => ({
        url: "/auth/signin",
        method: "POST",
        body: payload,
      }),
    }),
    resendEmailLink: builder.query({
      query: (id) => ({
        url: "/auth/resend/verificationlink",
        method: "Get",
        params: {id},
      }),
    }),
    ssoLogin: builder.query({
      query: (ssoToken) => ({
        url: `/sso/login?token=${ssoToken}`,
        method: "GET",
      }),
    }),
    forgetPasswordEmail:builder.query({
      query: (email) => ({
        url: `auth/password/reset?email=${email}`,
        method:'GET'
      })
    }),
    forgetPasswordSetterApi:builder.mutation({
      query: (payload) => ({
        url: `auth/password/change`,
        method:'POST',
        body:payload
      })
    }),
    logoutUser:builder.query({
      query: () => ({
        url: `user/logout`,
        method:'GET'
      })
    }),
    fetchUser:builder.query({
      query: () => ({
        url: `user/fetch/details`,
        method:'GET'
      })
    }),
    updateExistingUser:builder.mutation({
      query: (payload) => ({
        url: `user/update/existinguser`,
        method:'PUT',
        body:payload
      })
    }),
    checkPassword:builder.mutation({
      query: (payload) => ({
        url: `user/check/password`,
        method:'POST',
        body:payload
      })
    }),
    updatePassword:builder.mutation({
      query: (payload) => ({
        url: `user/update/password`,
        method:'POST',
        body:payload
      })
    }),
    getWidgetData:builder.query({
      query: (studentId) => ({
        url: studentId.id ? `/report/dashbaordwidgetdetails?userId=${studentId.id}` :`/report/dashbaordwidgetdetails`,
        method:'GET'
      })
    }),
    loginOauthUser:builder.query({
      query: (code) => ({
        url: `/auth/oauth/authentication?code=${code}`,
        method:'GET',
      })
    }),
  }),
});
    export const {useSignupMutation,useSignInMutation,useLazySsoLoginQuery,useLazyResendEmailLinkQuery,useLazyForgetPasswordEmailQuery,useForgetPasswordSetterApiMutation,useLazyLogoutUserQuery,useFetchUserQuery,useUpdateExistingUserMutation,useCheckPasswordMutation,useUpdatePasswordMutation,useGetWidgetDataQuery,useLazyLoginOauthUserQuery} = loginApi