import { createApi } from "@reduxjs/toolkit/query/react";
import { getItem } from "../../utils/token";
import customBaseQuery from "./customBaseQuery";
import {serviceUrl} from "../../apiConfig"
import { getMemoizedRefreshToken, getTokenExpiry } from "../../utils";

const api = createApi({
  baseQuery: customBaseQuery({
    baseUrl: serviceUrl,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      headers.set("Accept", "application/json");
      headers.set("Content-Type", "application/json");
      if (endpoint !== "countyGetAll" && endpoint !== 'updateUser') {
        let token = getItem("token");
        if (token) {
          const isTokenExpired = getTokenExpiry();
          if(isTokenExpired){
            token = await getMemoizedRefreshToken();
          }
          headers.set("Authorization", `Bearer ${token}`);
       }
     }
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default api;
