import React from 'react'
import ErrorLogo from '../assets/Images/PageNotFoundError.svg'
import AuthButton from './LogInPage/LoginButton';
import { RxHome } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setErrorPage } from '../redux/Slices/loaderSlice';
import { setIsTestInstructionsOpen, setIsTestStarted } from '../redux/Slices/startFullTestSlice';

const ApiErrorPage = () => {
    const navigate =  useNavigate();
    const dispatch = useDispatch();

    const handleBackToHome = () => {
      navigate('/dashboard');
      dispatch(setErrorPage(false));
      dispatch(setIsTestStarted(false));
      dispatch(setIsTestInstructionsOpen(false));
    }
    
  return (
    <div className='flex flex-col justify-end gap-9 self-stretch h-[80vh]'>
      <div className='flex flex-col items-center justify-center gap-9'>
        <h1 className='font-gully text-[40px] font-bold text-appPrimaryColor'>
            Oops! Something Went Wrong...
        </h1>

        <img src={ErrorLogo} alt='error-logo' className='mx-auto w-[400px]'/>

        <div className='font-gully text-[21px] font-medium text-center'>
          <p>Need Help? Ran into a problem? Reach out to our support</p>
          <p>team at <a href="mailto:support@fullmarks.io" className="text-appPrimaryColor underline"> support@fullmarks.io</a>. The more details you can</p>
          <p>provide, the faster we can help you.</p>
        </div>
      </div>

      <div className='mx-auto'>
        <AuthButton
            text="Return To Home"
            icon={<RxHome size={24} />}
            iconPosition="left"
            onClick={handleBackToHome}
            loader={false}
        />
      </div>
    </div>
  )
}

export default ApiErrorPage