import { ReactNode, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getItem } from "../utils/token";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

import Header from "../pages/NewDashboard/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/Store/store";
import {
  setIsTestInstructionsOpen,
  setIsTestStarted,
} from "../redux/Slices/startFullTestSlice";
import Loader from "./ReUsableComponents/Loader";
import { setErrorPage } from "../redux/Slices/loaderSlice";

interface Body {
  children: ReactNode;
}

const Layout = ({ children }: Body) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {isTestStarted, isTestInstructionsOpen} = useSelector((state:RootState) => state.root.startFullTest)
  const {errorpage} = useSelector((state:RootState) => state.root.loaderSlice)
  const [isLoading, setIsLoading] = useState(true);
  const [isLocation, setIsLocation] = useState(false);

  const token = getItem("token");
  const isLogIn = getItem("isLogin");
  const decoded: any = token && jwtDecode(token);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeRef: any = null;
    if (token) {
      setIsLoading(false);
      const expiryTime = decoded.exp
        ? new Date(decoded.exp * 1000).getTime()
        : 0;
      const currentTime = new Date().getTime();
      const timeout = expiryTime - currentTime;
      const onExpiry = () => {
        dispatch(setIsTestStarted(false));
        dispatch(setIsTestInstructionsOpen(false));
        toast.error("Your Session Expired");
        navigate("/");
         const route = getItem("route");
         if (route) {
           const keyToKeep = "route";
           for (let i = 0; i < localStorage.length; i++) {
             const key = localStorage.key(i);
             if (key && key !== keyToKeep) {
               localStorage.removeItem(key);
             }
           }
         } else {
           localStorage.clear();
         }
      };
      if (timeout > 0) {
        timeRef = setTimeout(onExpiry, timeout);
      } else {
        onExpiry();
      }
      return () => {
        if (timeRef) clearTimeout(timeRef);
      };
    }
  }, [navigate, token]);

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/home" ||
      location.pathname === "/login"
    ) {
      localStorage.clear();
    }
  }, [location]);

  useEffect(() => {
    const pathMatch =
      location.pathname.includes("/practices/") ||
      location.pathname.includes("/categories/");
    setIsLocation(pathMatch);
  }, [location.pathname]);

  useEffect(() => {
    if (!isLogIn || !token) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (errorpage) {
      navigate("/error");
    }
  }, [errorpage]);

  return (
    <div>
      {isLoading ? (
        <div className="h-screen w-full flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {isTestStarted || isTestInstructionsOpen ? null : <Header />}
          <main>
            <div className="flex-1 flex flex-col">{children}</div>
          </main>
        </>
      )}
    </div>
  );
};

export default Layout;
