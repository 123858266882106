import { createSlice } from "@reduxjs/toolkit";

interface Loader{
    isLoading:boolean,
    isError:boolean,
    error:string,
    errorpage:boolean,
}

const initialState : Loader ={
isLoading :false,
isError:false,
error:'',
errorpage:false,
}

const loaderSlice = createSlice({
    name:"loader",
    initialState,
    reducers:{
        setLoader:(state,action) => {
            state.isLoading = action.payload.isLoading;
            state.isError = action.payload.isError;
            state.error= action.payload.error 
        },
        setErrorPage:(state,action) => {
            state.errorpage = action.payload;
        }
    }
})

export const {setLoader,setErrorPage} = loaderSlice.actions;
export default loaderSlice.reducer