import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
interface ShortcutsType {
  id: string;
  action: string;
  keys: string;
}
const shortcutsMCQ = [
  { id: "1", action: "Open/Close Keyboard Shortcuts", keys: "F1" },
  { id: "2", action: "Navigate Exam Regions", keys: "Shift + F6" },
  { id: "3", action: "Zoom In", keys: "Control + Plus (+)" },
  { id: "4", action: "Zoom Out", keys: "Control + Minus (-)" },
  { id: "5", action: "Zoom back to 100%", keys: "Control + 0" },
  { id: "6", action: "Back", keys: "Control + Alt + B" },
  { id: "7", action: "Next", keys: "Control + Alt + X" },
  { id: "8", action: "Open/Close Question menu", keys: "Control + Alt + G" },
  { id: "9", action: "Help", keys: "Control + Alt + H" },
  {
    id: "10",
    action: "Open/Close Directions",
    keys: "Control + Alt + Shift + D",
  },
  { id: "11", action: "Open/Close Line Reader", keys: "Control + L" },
  {
    id: "12",
    action: "Hide/Show Timer or Close 5-Minute Message",
    keys: "Control + Alt + T",
  },
  {
    id: "13",
    action: "Pause Timer (w/Certain accommodations only)",
    keys: "Control + Alt + P",
  },
  { id: "14", action: "Mark for Review", keys: "Control + Alt + V" },
  { id: "15", action: "Highlights & Notes", keys: "Control + H" },
  { id: "16", action: "Open/Close Calculator", keys: "Control + Alt + C" },
  { id: "17", action: "Open/Close Reference Sheet", keys: "Control + Alt + R" },
  { id: "18", action: "Select/Deselect Option A", keys: "Control + Shift + 1" },
  { id: "19", action: "Select/Deselect Option B", keys: "Control + Shift + 2" },
  { id: "20", action: "Select/Deselect Option C", keys: "Control + Shift + 3" },
  { id: "21", action: "Select/Deselect Option D", keys: "Control + Shift + 4" },
  { id: "22", action: "Select/Deselect Option E", keys: "Control + Shift + 5" },
  { id: "23", action: "Option Eliminator Mode", keys: "Control + Alt + O" },
  { id: "24", action: "Eliminate Option A", keys: "Control + Alt + 1" },
  { id: "25", action: "Eliminate Option B", keys: "Control + Alt + 2" },
  { id: "26", action: "Eliminate Option C", keys: "Control + Alt + 3" },
  { id: "27", action: "Eliminate Option D", keys: "Control + Alt + 4" },
  { id: "28", action: "Eliminate Option E", keys: "Control + Alt + 5" },
  { id: "29", action: "Expand/Collapse Left", keys: "Control + Alt + L" },
  { id: "30", action: "Expand/Collapse Right", keys: "Control + Alt + R" },
];

const shortcutsFIB = [
  { id: "1", action: "Bold", keys: "Control + B" },
  { id: "2", action: "Italics", keys: "Control + I" },
  { id: "3", action: "Underline", keys: "Control + U" },
  { id: "4", action: "Superscript", keys: "Control + Shift + Plus(+)" },
  { id: "5", action: "Subscript", keys: "Control + Shift + Minus(-)" },
  { id: "6", action: "Undo", keys: "Control + Z" },
  { id: "7", action: "Redo", keys: "Control + Y" },
  { id: "8", action: "Cut", keys: "Control + X" },
  { id: "9", action: "Copy", keys: "Control + C" },
  { id: "10", action: "Paste", keys: "Control + V" },
  { id: "11", action: "Insert Symbols", keys: "Control + Shift + /" },
  { id: "12", action: "Focus on Editor Toolbar", keys: "Alt + F10" },
];

const ShortcutsDialog = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [show, setShow] = useState<Boolean>(true);
  const dispatch = useDispatch();
  const handleExpandCollapse = (bool: Boolean) => setShow(bool);
  const handleClose = () => {
    dispatch(closeDialog());
  };
  return (
    <div className="bg-white rounded-lg p-6 ">
      {/* Header */}
      <div className="flex justify-between items-center pb-2 ">
        <h2 className="text-[36px] font-normal font-inter ">
          Keyboard Shortcuts
        </h2>
        <button
          onClick={handleClose}
          className="text-2xl text-gray-700 w-6 h-6"
        >
          &times;
        </button>
      </div>

      <div className="flex justify-end items-center w-full text-primaryColor cursor-pointer border-b border-b-lightGrey">
        <div className="flex items-center gap-4">
          <button
            onClick={() => handleExpandCollapse(true)}
            className="font-inter text-base font-semibold hover:underline"
          >
            Expand All
          </button>
          <div className="w-[1px] h-[30px] bg-darkGrey"></div>
          <button
            onClick={() => handleExpandCollapse(false)}
            className="font-inter text-base font-semibold hover:underline"
          >
            Collapse All
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="text-gray-700 h-[500px] overflow-y-auto pb-[18px] px-[10px]  mt-5 scroll4">
        <button
          className={` group flex justify-between w-full py-[18px] items-center ${
            show ? "" : "hover:bg-lightGrey"
          }`}
          onClick={() => setShow(!show)}
        >
          <p className="group-hover:underline">Keyboard Shortcuts: Windows </p>
          <p className=" text-xl ">{show ? "−" : "+"}</p>
        </button>
        {show && (
          <div className="bg-custom-light-grey-gradient  py-[1.125rem] px-[0.625rem]">
            <div className="mt-2 mb-5">
              <p>
                The following keyboard shortcuts have been configured to
                navigate to important functions within the digital exam.
              </p>

              <p className="mt-4 text-center">
                Keyboard Shortcuts for Multiple-Choice Questions
              </p>
            </div>

            {/* Table */}
            <table className="min-w-full divide-y divide-gray-200 border border-lightGrey border-collapse">
              <thead className="border-collapse border border-lightGrey">
                <tr>
                  <th className="px-4 py-2 text-left font-bold text-white bg-gray-700">
                    Action
                  </th>
                  <th className="px-4 py-2 text-left font-bold text-white bg-gray-700">
                    Shortcut
                  </th>
                </tr>
              </thead>
              <tbody>
                {shortcutsMCQ.map((shortcut: ShortcutsType) => (
                  <tr
                    key={shortcut.id}
                    className="border border-darkGrey bg-white"
                  >
                    <td className="px-4 py-3 text-left font-bold">
                      {shortcut.action}
                    </td>
                    <td className="px-4 py-3 border-l border-darkGrey">
                      {shortcut.keys}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-2 mb-5 leading-5">
              <p>
                Note:For multiselect options,use the space bar to make your
                selection.
              </p>
              <p className="mt-4 text-center">
                keyboard shortcuts for Free-Response Questions and
                Student-Produced Responses.
              </p>
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left font-bold text-white bg-gray-700">
                      Action
                    </th>
                    <th className="px-4 py-2 text-left font-bold text-white bg-gray-700">
                      Shortcut
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {shortcutsFIB.map((shortcut: ShortcutsType) => (
                    <tr
                      key={shortcut.id}
                      className="border border-darkGrey bg-white"
                    >
                      <td className="px-4 py-3 text-left font-bold">
                        {shortcut.action}
                      </td>
                      <td className="px-4 py-3 border-l border-darkGrey">
                        {shortcut.keys}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>

      {/* Close Button */}
      <div className="flex justify-center">
        <button
          onClick={handleClose}
          className="flex py-3 px-5 mt-12 text-sm text-center items-center gap-[10px] bg-vibrantYellow rounded-[49px] border border-lightBlack font-semibold btn-hover-style"
        >
          Close
        </button>
      </div>
    </div>
  );
};
export default ShortcutsDialog;
