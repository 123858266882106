import React, { ReactNode, useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { IoMdClose, IoMdMore } from "react-icons/io";
import { LuHighlighter, LuKeyboard, LuStickyNote } from "react-icons/lu";
import { LiaCalculatorSolid } from "react-icons/lia";
import Timer from "./Timer";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { getItem } from "../../utils/token";
import { RootState } from "../../redux/Store/store";
import { useDispatch, useSelector } from "react-redux";
import ClockIcon from "../../assets/Images/clockIcon.svg";
import ReferenceIcon from "../../assets/Images/x-square.png";
import assistive_technology from "../../assets/Images/assistive_technology.svg";
import LineReaderIcon from "../../assets/Images/LineReaderIcon.svg";
import saveIcon from "../../assets/Images/Save.jpg";

import {
  setIsHighlightNotes,
  setHasHighlightPopupBeenShown,
  setIsHighlightPopupVisible,
} from "../../redux/Slices/startFullTestSlice";
import { FiHelpCircle } from "react-icons/fi";
import { openDialog } from "../../redux/Slices/dialogSlice";


export type OptionType = {
  label: string | ReactNode;
  onClick?: () => void;
};


const TestHeader = ({
  headerHeading,
  handleDirectionModal,
  dialogModalOpen,
  getIsWritingModule,
  handleLineReader,
  popupRef,
}: {
  headerHeading: string;
  handleDirectionModal: () => void;
  dialogModalOpen: boolean;
  getIsWritingModule: () => boolean;
  handleLineReader: () => void;
  popupRef: React.RefObject<HTMLDivElement>;
}) => {
  const [hideTimer, setHideTimer] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [dismissedWarning, setDismissedWarning] = useState(false);
  const isPreview = getItem("testType") === "PREVIEW";
  const dispatch = useDispatch();
  const {
    currentTime,
    isHighlightNotes,
    hasHighlightPopupBeenShown,
    isHighlightPopupVisible,
  } = useSelector((state: RootState) => state.root.startFullTest);

  const handleCloseWarning = () => {
    setWarningMessage(false);
    setDismissedWarning(true);
  };

  const handleHighlight = () => {
    if (!hasHighlightPopupBeenShown && !isHighlightPopupVisible) {
      dispatch(setIsHighlightPopupVisible(true));
      dispatch(setHasHighlightPopupBeenShown(true));
    }
    dispatch(setIsHighlightNotes(!isHighlightNotes));
  };

  useEffect(() => {
    if (currentTime < 300 && !dismissedWarning && !isPreview) {
      setWarningMessage(true);
      setHideTimer(false);
    }
    return () => {
      setWarningMessage(false);
    };
  }, [currentTime]);

  const openCalculator = () => {
    const url = "https://www.desmos.com/calculator"; // URL of the calculator
    const name = "CalculatorWindow";
    const specs = "width=400,height=500,top=100,left=100,noopener"; // Customize the size and position

    if (url.startsWith("https://www.desmos.com")) {
      // Validate the URL
      window.open(url, name, specs);
    } else {
      console.error("Invalid URL");
    }
  };

  const openReferences = () => {
    const newPath = "/references"; // Change this to the desired path
    const newUrl = `${window.location.origin}${newPath}`;

    // Define the window specifications
    const name = "ReferenceWindow";
    const specs = "width=400,height=500,top=100,left=100"; // Customize the size and position

    // Open the new window
    window.open(newUrl, name, `${specs},noopener,noreferrer`);
  };

    const saveExitDialog = () => {
      dispatch(openDialog("saveExitDialogue"));
    };
    const shortcutsDialog = () => {
      dispatch(openDialog("shortcutsDialog"));
    };

   const options: OptionType[] = [
     {
       label: (
         <>
           <FiHelpCircle className="mr-4 text-xl" /> <span>Help</span>
         </>
       ),
       onClick: () => dispatch(openDialog("helpDialog")),
     },
     {
       label: (
         <>
           <LuKeyboard className="mr-4 text-xl" />
           <span>Shortcuts</span>
         </>
       ),
       onClick: () => shortcutsDialog(),
     },
     {
       label: (
         <>
           <img
             className="mr-4 text-xl"
             src={assistive_technology}
             alt="assistivetechnology"
           />
           <span>Assistive Technology</span>
         </>
       ),
       onClick: () => dispatch(openDialog("assistiveTechnologyDialog")),
     },
     {
       label: (
         <>
           <img
             className="mr-4 text-xl"
             src={LineReaderIcon}
             alt="Line-Reader-Icon"
           />
           <span>Line Reader</span>
         </>
       ),
       onClick: () => handleLineReader(),
     },
     {
       label: (
         <>
           <img className="mr-4" src={saveIcon} alt="save" />{" "}
           <span>
             {" "}
             {getItem("testType") === "PREVIEW"
               ? "Exit the Exam"
               : "Save & Exit"}
           </span>
         </>
       ),
       onClick: () => saveExitDialog(),
     },
   ];


  return (
    <div className="!h-[78px] bg-[#E6EDF8] dashed-border dashed-border-bottom flex py-[14px] px-11 justify-between items-center">
      <div className="flex justify-between items-center w-[48%]">
        <div className=" flex flex-col">
          <div className="text-base font-semibold">
            {headerHeading ? headerHeading.split("Questions").join("").trim() : ""}
          </div>
          <button
            className="flex cursor-pointer text-sm font-semibold mt-3"
            onClick={handleDirectionModal}
          >
            <p>Directions</p>
            <div className="mt-[1px]">
              {dialogModalOpen ? (
                <MdKeyboardArrowUp size={19} />
              ) : (
                <MdKeyboardArrowDown size={19} />
              )}
            </div>
          </button>
        </div>
      </div>

      <div
        className={`flex ${
          hideTimer ? "w-[84px]" : "w-[76px]"
        } gap-2 flex-col justify-between items-center`}
      >
        <img
          src={ClockIcon}
          alt="clock-icon"
          className={`w-5 h-5 shrink-0 ${hideTimer ? "block" : "hidden"}`}
        />
        <div
          className={`text-center text-base font-medium h-5 ${
            hideTimer ? "hidden" : "block"
          } ${currentTime < 300 && !isPreview && "text-red-700"}`}
        >
          <div className="inter text-base font-medium">
            <Timer />
          </div>
        </div>
        {(currentTime > 300 || isPreview) && (
          <button
            className="border border-lightBlack rounded-[50px] w-[67px]  py-1 text-[11px] font-semibold gap-[10px] active:underline ease-linear"
            onClick={() => setHideTimer(!hideTimer)}
          >
            {hideTimer ? "Show" : "Hide"}
          </button>
        )}
      </div>
      {warningMessage && (
        <div
          className="absolute top-28 w-[400px] h-[46px] bg-lightBlack flex px-[23px] justify-center items-center gap-[30px] rounded-md z-10"
          style={{ left: "calc(50% - 200px)" }}
        >
          <p className="font-inter text-base text-white font-semibold">
            5 minutes left in this part of the test.
          </p>
          <button onClick={() => handleCloseWarning()}>
            <IoMdClose className="w-6 h-6 text-white" />
          </button>
        </div>
      )}

      <div className="flex items-center w-[48%] justify-end">
        <div
          className={`pr-4 flex flex-col items-center gap-x-2 cursor-pointer ${
            getIsWritingModule() ? "hidden" : "flex"
          }`}
        >
          <LiaCalculatorSolid
            className="mb-1"
            size={24}
            onClick={openCalculator}
          />
          <div className="text-xs">Calculator</div>
        </div>
        <div
          className={`pr-4 flex flex-col cursor-pointer ${
            getIsWritingModule() ? "hidden" : "flex"
          }`}
        >
          <button
            onClick={openReferences}
            style={{
              border: "none",
              background: "none",
              padding: 0,
              cursor: "pointer",
            }}
            className="flex items-center flex-col"
          >
            <img src={ReferenceIcon} className="mb-1" alt="reference" />
            <div className="text-xs">Reference</div>
          </button>
        </div>
        <div className="flex items-center justify-center">
          {getIsWritingModule() && (
            <div className="relative">
              <button
                onClick={handleHighlight}
                className={`${
                  isHighlightNotes
                    ? "border-b-2 border-lightBlack pb-[2px]"
                    : "pb-1"
                } `}
              >
                <div className="flex items-center pt-2 pb-1 gap-1 justify-center">
                  <LuHighlighter className="text-xl" />
                  <LuStickyNote className="text-xl" />
                </div>
                <p className="text-xs">Highlight & Notes</p>
              </button>

              {isHighlightPopupVisible && (
                <p
                  ref={popupRef}
                  className="absolute -bottom-[140%] w-40 mx-auto left-1/2 transform -translate-x-1/2 mb-3 px-3 py-1 text-xs text-white bg-gray-700 rounded shadow-lg opacity-100 transition-opacity"
                >
                  <p className="text-sm font-bold">Highlight mode on:</p>
                  <p>Select text to create a highlight automatically.</p>
                  <span className="absolute -top-1 left-[50%] transform -translate-x-1/2 w-3 h-3 bg-gray-700 rotate-45"></span>
                </p>
              )}
            </div>
          )}

          <button className="flex items-center">
            <div className="z-50 flex flex-col items-center">
              <Dropdown
                buttonLabel={<IoMdMore size={24} className="mb-1" />}
                options={options}
              />
              <p className="text-xs">More</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestHeader;
