import React, { useEffect, useRef } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { Category, FilterValue } from "./QuestionsLeftPane";

type FiltersPopupType = {
  setShowFilters: (val: boolean) => void;
  showFilters: boolean;
  toggleFilter: (val1: Category, val2: FilterValue) => void;
};

const FiltersPopUp = ({
  setShowFilters,
  showFilters,
  toggleFilter,
}: FiltersPopupType) => {
  const { filters } = useSelector(
    (state: RootState) => state.root.startFullTest
  );

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowFilters(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowFilters]);

  return (
    <div
      ref={popupRef}
      style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
      className="absolute w-[282px] left-[70%] top-0 bg-white shadow-lg rounded-lg border p-4 z-10"
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2 text-appPrimaryColor">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className=" hover:bg-gray-100 rounded"
          >
            <BiFilterAlt size={24} className="font-medium" />
          </button>
          <p className="text-[21px]">Filters</p>
        </div>
        <button
          onClick={() => setShowFilters(!showFilters)}
          className="p-1 hover:bg-gray-100 rounded"
        >
          <IoCloseSharp
            size={24}
            className="font-medium text-appPrimaryColor"
          />
        </button>
      </div>
      <div className="space-y-4 text-[#283044]">
        <div className="border-b border-[#E1E1E1] pb-4">
          <h3 className="font-semibold uppercase text-xs mb-2 text-[#646464]">
            Section
          </h3>
          <label className="mb-5 custom-checkbox">
            Reading & Writing Section{" "}
            <input
              type="checkbox"
              checked={filters.section.readingWriting}
              onChange={() => toggleFilter("section", "readingWriting")}
            />
            <span className="filterCheckmark" />
          </label>
          <label className="custom-checkbox mb-2">
            Math{" "}
            <input
              type="checkbox"
              checked={filters.section.math}
              onChange={() => toggleFilter("section", "math")}
            />
            <span className="filterCheckmark" />
          </label>
        </div>

        <div className="border-b border-[#E1E1E1] pb-4">
          <h3 className="font-semibold uppercase text-xs mb-2 text-[#646464]">
            Module {""}
          </h3>
          <label className="flex items-center gap-2 mb-5 custom-checkbox">
            <input
              type="checkbox"
              checked={filters.module.module1}
              onChange={() => toggleFilter("module", "module1")}
            />{" "}
            Module 1 <span className="filterCheckmark" />
          </label>

          <label className="flex items-center gap-2 custom-checkbox">
            <input
              type="checkbox"
              checked={filters.module.module2}
              onChange={() => toggleFilter("module", "module2")}
            />{" "}
            Module 2 <span className="filterCheckmark" />
          </label>
        </div>

        <div>
          <h3 className="font-semibold uppercase text-xs mb-2 text-[#646464]">
            Answer Type{" "}
          </h3>
          <label className="flex items-center gap-2 mb-5 custom-checkbox">
            <input
              type="checkbox"
              checked={filters.answerType.correct}
              onChange={() => toggleFilter("answerType", "correct")}
            />{" "}
            Correct <span className="filterCheckmark" />
          </label>
          <label className="flex items-center gap-2 custom-checkbox mb-5">
            <input
              type="checkbox"
              checked={filters.answerType.incorrect}
              onChange={() => toggleFilter("answerType", "incorrect")}
            />{" "}
            Incorrect <span className="filterCheckmark" />
          </label>
          <label className="flex items-center gap-2 custom-checkbox">
            <input
              type="checkbox"
              checked={filters.answerType.omitted}
              onChange={() => toggleFilter("answerType", "omitted")}
            />{" "}
            Skipped <span className="filterCheckmark" />
          </label>
        </div>
      </div>
    </div>
  );
};

export default FiltersPopUp;
